
<template>
  <div id="page-user-rdit">
    <div v-if="!isLoading" class="vx-row">
      <div class="vx-col lg:w-2/3 w-full">
        <vx-card v-if="user" >
          <div slot="no-body" class="tabs-container px-6 pt-6">
            <vs-tabs class="tab-action-btn-fill-conatiner">
              <vs-tab :label="!isSmallerScreen ? 'Account' : ''" icon-pack="feather" icon="icon-user">
                <div class="tab-text">
                  <user-edit-tab-account class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Settings' : ''" icon-pack="feather" icon="icon-settings">
                <div class="tab-text">
                  <user-edit-tab-information class="mt-4" />
                </div>
              </vs-tab>
              <vs-tab :label="!isSmallerScreen ? 'Password' : ''" icon-pack="feather" icon="icon-lock">
                <div class="tab-text">
                  <user-edit-tab-password class="mt-4" />
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </vx-card>
      </div>
    </div>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import UserEditTabAccount     from "./UserEditTabAccount.vue"
import UserEditTabInformation from "./UserEditTabInformation.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  components: {
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabPassword,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    user(){
      return this.$store.state.userList.user
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  methods: {
    fetchUser(){
      this.isLoading = true
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created() {
    if(!this.$store.getters['userList/totalRoles']){
      this.$store.dispatch("userList/fetchRoles")
    }
    if(!this.$store.state.countries.length){
      this.$store.dispatch('fetchCountries')
    }
    if(!this.$store.state.languages.length){
      this.$store.dispatch('fetchLanguages')
    }
    if(!this.$store.state.skills.length){
      this.$store.dispatch('fetchSkills')
    }
    this.fetchUser()
  }
}

</script>
