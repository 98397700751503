
<template>
  <div id="user-edit-tab-info">
    <form>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row">
            <img :src="loadedImage" class="mr-8 rounded h-24 w-24" />
            <div>
              <p class="text-lg font-medium mb-2 mt-4 sm:mt-0">{{ data.first_name  }} {{ data.last_name  }}</p>
              <input type="file" size="small" class="hidden" ref="update_avatar_input" @change="fileInputReader" accept="image/*">
              <vs-button size="small" class="mr-4 mb-4" @click="$refs.update_avatar_input.click()">Update Image</vs-button>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
         <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="First name" v-model="data.first_name"
          v-validate="'required|alpha_spaces'" placeholder="first name" name="first_name" />
          <span class="text-danger text-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="Last name" v-model="data.last_name"
           v-validate="'required|alpha_spaces'" placeholder="last name" name="last_name" />
          <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="Username" v-model="data.username"
           v-validate="'required'" placeholder="username" name="username" />
          <span class="text-danger text-sm"  v-show="errors.has('username')">{{ errors.first('username') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" placeholder="email" label="Email" v-model="data.email"
           type="email" v-validate="'required|email'" name="email" />
          <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4" label="Mobile" v-model="data.mobile"
          v-validate="{ regex: '^\\+?([0-9]+)$' }" placeholder="mobile" name="mobile" />
            <span class="text-danger text-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <div class="mt-4">
            <vs-select autocomplete v-validate="'required'" v-model="data.role"
              name="role" label="Role *" :placeholder="data.role | capitalize"
              :disabled="$route.params.userId == activeUser.id" class="select-large mt-5 w-full">
                <vs-select-item :key="index" :value="item.name"
                 :text="item.label | capitalize" v-for="(item,index) in roleOptions" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('role')">{{ errors.first('role') }}</span>
          </div>
        </div>
        <div v-if="user.role == 'seller'" class="vx-col md:w-1/2 w-full">
          <div class="mt-4">
            <vs-select autocomplete v-validate="'required'"
              v-model="data.level" name="level" label="Level *" placeholder="--select level--"
               :disabled="$route.params.userId == activeUser.id" class="select-large mt-5 w-full">
                <vs-select-item :key="lIndex" :value="level.value"
                :text="level.text" v-for="(level, lIndex) in levelOptions" />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('level')">{{ errors.first('level') }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="mt-4">
             <label class="text-sm">Status</label>
              <div class="mt-2">
                <vs-radio v-model="data.status" vs-value="active" class="mr-4">Active</vs-radio>
                <vs-radio v-model="data.status" vs-value="deactivated" class="mr-4">Deactivated</vs-radio>
                <vs-radio v-model="data.status" vs-value="blocked" class="mr-4">Blocked</vs-radio>
              </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="mt-4">
             <label class="text-sm">Verification</label>
              <div class="mt-2">
                <vx-tooltip :text="data.is_verified ?' This user is verified ':'This user is not verified'" position="right">
                  <vs-switch v-model="data.is_verified">
                    <span slot="on">true</span>
                    <span slot="off">false</span>
                  </vs-switch>
                </vx-tooltip>
              </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button type="filled" @click.prevent="updateUser" class="mt-2 block">Update</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="patchUser">Reset</vs-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

export default {

  data() {
    return {
      loadedImage: "",
      newImage: "",
      data: {},
      levelOptions: [
        { value:1, text:"New Seller"},
        { value:2, text:"Level One"},
        { value:3, text:"Level Two"},
        { value:4, text:"Top Rated"},
        { value:5, text:"Pro Seller"}
      ]
    }
  },
  computed: {
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    user(){
      return this.$store.state.userList.user
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          formData.append('image', this.newImage )
          this.$vs.loading({ color: "#444",type: "sound" })
          this.$http.post(`/users/${this.user.id}/update`,formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit("userList/SET_USER", response.data.data.user)
              this.patchUser();
            }
          })
        }
      })
    },
    fileInputReader(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader()
        reader.onload = e => {
          this.loadedImage = e.target.result
        }
        this.newImage = input.target.files[0]
        reader.readAsDataURL(input.target.files[0])
      }
    },
    patchUser(){
      this.data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        username: this.user.username,
        email: this.user.email,
        mobile: this.user.mobile,
        role: this.user.role,
        status: this.user.status,
        level: this.user.level,
        is_verified: this.user.is_verified,
      }
      this.loadedImage = this.absoluteUrl(this.user.image)
      this.newImage = ""
    }
  },
  created(){
    this.patchUser()
  },
}
</script>

<style type="text/css">
  vs-select:placeholder{
    text-transform: uppercase;
  }
</style>
